import React from "react";
import { Router } from "@reach/router";

import Page from "../templates/page";
import Location from "../templates/location";
import Post from "../templates/post";

const Index = (props) => {
  return (
    <Router>
      <Location {...props} path="/locations/*" />
      <Post {...props} path="/posts/*" />
      <Page {...props} path="/*" />
    </Router>
  );
};

Index.displayName = "Index";

export default Index;
